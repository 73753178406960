import { useState } from "react";
import { ArrowRight } from "lucide-react";

interface ExpandingPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  children: React.ReactNode;
  className?: string;
}

const ExpandingPanel = ({
  title,
  children,
  className,
}: ExpandingPanelProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border-b border-black">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left"
      >
        <h3 className="text-2xl lg:text-3xl leading-[2.25rem] font-freightBig mb-4 flex items-center">
          {title}
          <span className="ml-4">
            <ArrowRight className="size-6" />
          </span>
        </h3>
      </button>
      {isOpen && (
        <div className="text-base leading-7 mb-4">
          {children}
        </div>
      )}
    </div>
  );
};

export default ExpandingPanel;
