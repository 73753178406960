import Button from "../ui/button";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { formValidation } from "@/lib/formValidation";

interface RequestCallProps {
  data?: any;
}

const procedures = [
  "Facelift",
  "Rhinoplasty", 
  "Blepharoplasty",
  "Neck Lift",
  "Other"
];

const inputs = [
  { type: "text", placeholder: "First Name" },
  { type: "text", placeholder: "Last Name" },
  { type: "tel", placeholder: "Phone Number" },
  { type: "email", placeholder: "Email Address" },
];

type FormData = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  procedure: string;
  city: string; // Honeypot
  checkbox: boolean;
};

export default function RequestCall({ data }: RequestCallProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, setValue, watch } = useForm<FormData>({
    mode: "onBlur",
  });

  const selectedProcedure = watch("procedure");

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    setFormError("");
    setFormSuccess("");

    try {
      const response = await fetch('/api/request-call/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (!response.ok) throw new Error();
      setFormSuccess("We'll call you back soon!");
    } catch (error) {
      setFormError("Failed to submit. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="relative rounded-[4rem] bg-black w-full max-w-[109rem] -mt-[3.6rem] h-auto xl:h-[7.2rem] mx-auto flex flex-col xl:flex-row items-center justify-between p-8 xl:px-16 gap-8 xl:gap-0">
      <div className="text-white text-2xl font-bold font-serenity tracking-wide text-center xl:text-left min-w-[6.5rem]">
        Request a call back
      </div>

      <div className="hidden xl:block h-[5.75rem] origin-top-left border border-white/60" />

      <form onSubmit={handleSubmit(onSubmit)} className="flex items-center gap-12 flex-1 max-w-[70.875rem] h-auto xl:h-[6.5rem] w-full">
        {formError && <div className="absolute top-2 left-1/2 -translate-x-1/2 text-red-500 text-sm">{formError}</div>}
        
        
        <div className="flex flex-col xl:flex-row justify-between items-stretch w-full gap-8 xl:gap-4">
          <input type="hidden" {...register('city')} />
          
          <div className="flex-col justify-start items-stretch inline-flex flex-1 min-w-[9.5rem]">
            <input
              {...register('firstName', { required: true })}
              type="text"
              placeholder="First Name"
              className="bg-transparent text-white text-base font-light font-serenity leading-[1.88rem] tracking-wide border-none focus:outline-none"
            />
            <div className="self-stretch h-0 border border-white/30" />
          </div>

          <div className="flex-col justify-start items-stretch inline-flex flex-1 min-w-[9.5rem]">
            <input
              {...register('lastName', { required: true })}
              type="text"
              placeholder="Last Name"
              className="bg-transparent text-white text-base font-light font-serenity leading-[1.88rem] tracking-wide border-none focus:outline-none"
            />
            <div className="self-stretch h-0 border border-white/30" />
          </div>

          <div className="flex-col justify-start items-stretch inline-flex flex-1 min-w-[9.5rem]">
            <input
              {...register('phone', { 
                required: true,
                pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
              })}
              type="tel"
              placeholder="Phone Number"
              className="bg-transparent text-white text-base font-light font-serenity leading-[1.88rem] tracking-wide border-none focus:outline-none"
            />
            <div className="self-stretch h-0 border border-white/30" />
          </div>

          <div className="flex-col justify-start items-stretch inline-flex flex-1 min-w-[9.5rem]">
            <input
              {...register('email', { 
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              })}
              type="email"
              placeholder="Email Address"
              className="bg-transparent text-white text-base font-light font-serenity leading-[1.88rem] tracking-wide border-none focus:outline-none"
            />
            <div className="self-stretch h-0 border border-white/30" />
          </div>

          <div className="relative flex-col justify-start items-stretch inline-flex flex-1 min-w-[11.5rem]">
            <input
              type="text"
              readOnly
              value={selectedProcedure || "Choose Your Procedure"}
              onClick={() => setIsOpen(!isOpen)}
              className="bg-transparent text-white text-base font-light font-serenity leading-[1.88rem] tracking-wide border-none focus:outline-none cursor-pointer"
            />
            <div className="absolute right-0 top-[0.6rem] pointer-events-none">
              <svg className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`} fill="none" stroke="white" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            <div className="self-stretch h-0 border border-white/30" />

            {isOpen && (
              <div className="absolute top-[calc(100%+0.5rem)] left-0 w-full bg-black rounded-md shadow-lg z-10 border border-white/30">
                {procedures.map((procedure) => (
                  <div
                    key={procedure}
                    className="px-4 py-2 text-white hover:bg-white/10 cursor-pointer font-light font-serenity"
                    onClick={() => {
                      setValue('procedure', procedure);
                      setIsOpen(false);
                    }}
                  >
                    {procedure}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </form>

      <div className="flex flex-col items-center gap-2 w-full xl:w-auto max-w-[14rem] min-w-[13rem]">
        {formSuccess ? (
          <div className="px-5 w-full py-2.5  text-white text-xl font-medium font-serenity tracking-wide text-center">
            {formSuccess}
          </div>
        ) : (
          <button 
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            className="px-5 w-full py-2.5 bg-neutral-light rounded-[31.25rem] text-black text-xl font-medium font-serenity tracking-wide hover:bg-gray-400 transition-colors duration-300 disabled:opacity-50"
          >
            {isLoading ? 'Sending...' : 'Submit'}
          </button>
        )}
        <label className="flex items-center gap-2 cursor-pointer">
          <input type="checkbox" {...register('checkbox')} className="w-3 h-3 accent-primary" />
          <span className="text-xs text-white leading-tight text-center xl:text-left">
            I agree to receive personalised marketing texts and/or emails
          </span>
        </label>
      </div>
    </section>
  );
}
