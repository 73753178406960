import { sanitizeHTML } from "@/lib/utils";
import { getReadableImageUrl } from "@/lib/directus";

interface ReasonsBlepharoplastyData {
  heading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  video?: {
    id?: string;
    filename_disk?: string;
  };
}

export const fields = [
  "heading.text",
  "heading.title",

  "text.text",

  "video.id",
  "video.filename_disk"
] as const;

export default function ReasonsBlepharoplasty({
  data,
}: {
  data?: ReasonsBlepharoplastyData;
}) {
  return (
    <section
      id="reasons-blepharoplasty"
      className="bg-[#f9f9f9] py-[50px] pb-[34px] my-0"
    >
      <div className="container">
        <div className="mx-auto px-4 py-0 my-0 mb-[35px]">
          <div className="grid grid-cols-1 md:grid-cols-[57.8%_36.7%] gap-8">
            <div>
              <div className="text-center">
                <h2 
                  className="text-left font-freightBig text-[30px] md:text-[50px] sm:text-[30px] text-[#332f2c] leading-[1.1em] py-0 pb-4 my-0"
                  dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || data?.heading?.text || '')}
                />
              </div>
              <div className="text-left space-y-4" dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')} />
            </div>
            <div>
              <div className="max-h-[400px] w-full">
                <video controls className="w-full h-[400px]">
                  <source type="video/mp4" src={getReadableImageUrl(data?.video?.id || '', data?.video?.filename_disk || '')} />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
