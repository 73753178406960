import Link from "@/components/ui/Link";
import Button from "../ui/button";
import { sanitizeHTML } from "@/lib/utils";

interface ProfileAboutSplitGreyData {
  heading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  video?: {
    embed_url: string;
    embed_title: string;
  };
  button?: {
    label?: string;
    href?: string;
  };
}

export const fields = [
  "heading.text",
  "heading.title",
  "text.text",
  "video.embed_url",
  "video.embed_title",
  "button.label",
  "button.href",
] as const;

export default function ProfileAboutSplitGrey({
  data,
}: {
  data: ProfileAboutSplitGreyData;
}) {
  return (
    <section
      id="profile-about-split-grey"
      className="bg-[#ededed] py-[5.25rem] md:py-[7.5rem] lg:py-[5.25rem]"
    >
      <div className="container mx-auto px-4 md:px-8 lg:px-12 xl:px-16 max-w-[88rem]">
        <div className="flex flex-col lg:flex-row lg:items-start lg:space-x-[1.875rem] -mx-4">
          <div className="w-full lg:w-1/2 px-4">
            <h2 className="font-display mb-8 text-[1.875rem] md:text-[3rem] text-[#332f2c] leading-tight">
              {data?.heading?.text || data?.heading?.title || ""}
            </h2>
            <div className="mt-0 rounded-lg overflow-hidden aspect-[16/9]">
              <iframe
                src={`${data?.video?.embed_url}`}
                title={data?.video?.embed_title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full h-full"
                loading={"lazy"}
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-4 mt-5 lg:mt-[3.625rem]">
            <div
              className="prose prose-lg text-black font-aileron text-base leading-[1.5] max-w-full"
              dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")}
            />
            <div className="flex justify-center mt-[1.875rem]">
              <Button asChild>
                <Link href={data?.button?.href || "/book-a-consultation"}>
                  {data?.button?.label || "Book a consultation"}
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
