import Image from "next/image";
import Link from "@/components/ui/Link";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML } from "@/lib/utils";

interface PhilanthropySectionData {
  heading: {
    title?: string;
  };
  text: {
    text?: string;
  };
  image: {
    alt?: string;
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
  };
  button: {
    label?: string;
    href?: string;
  };
}

interface PhilanthropySectionProps {
  data?: PhilanthropySectionData;
}

export const fields = [
  "heading.title",
  "text.text",
  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download",
  "button.label",
  "button.href"
] as const;

export default function PhilanthropySection({
  data,
}: PhilanthropySectionProps) {
  return (
    <section
      id="PhilanthropySection"
      className="bg-black py-12 md:py-14 lg:py-16"
    >
      <div className="container">
        <div className="max-w-[1080px] w-[90%] mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-[5.5%]">
            <div className="relative">
              <Image
                src={getReadableImageUrl(
                  data?.image?.image_file?.image?.id || "",
                  data?.image?.image_file?.image?.filename_download || ""
                )}
                alt={data?.image?.alt || ''}
                width={1000}
                height={717}
                className="rounded-lg w-full h-auto"
                quality={95}
                loading={"lazy"}
              />
            </div>
            <div>
              <h2 className="font-freightBig text-3xl md:text-4xl text-white mb-8">
                {data?.heading?.title}
              </h2>
              <div className="text-white font-aileron text-base">
                <div
                  className="leading-relaxed"
                  dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')}
                />
                {data?.button?.href && (
                  <p className="my-4">
                    <Link
                      href={data?.button?.href}
                      className="text-white underline transition-colors"
                    >
                      {data?.button?.label}
                    </Link>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
